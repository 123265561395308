<template>
  <v-row>
    <!-- Sync pull fail alert -->
    <alert color="error"
           class="mx-2 mb-0 mt-2"
           v-if="!isSyncing && (!isEncPullSuccess || !isPatsPullSuccess)"
    >
      Your last sync failed to download some patients and encounters. To avoid further issues, please sync again to continue using Treasure X.
    </alert>
    <!-- sync status -->
    <v-col class="col-12 sync-status">
      <app-card-actions
        action-refresh
        @refresh="refreshEncounters"
      >
        <template slot="title">
          Sync Status
          <span v-if="patientsToSyncCount > 0">
            Patients to sync: <b>{{ patientsToSyncCount }}</b>
          </span>
          <span v-if="encountersToSyncCount > 0">
            Encounters to sync: <b>{{ encountersToSyncCount }}</b>
          </span>
          <span v-if="commentsToSyncCount > 0">
            Comments to sync: <b>{{ commentsToSyncCount }}</b>
          </span>
        </template>
        <template slot="before-actions">
          <chip
            :value="isEncountersSynced ? 'Synced' : 'Not Synced'"
            :icon="isEncountersSynced ? icons.mdiCloudCheckVariant : icons.mdiCloseCircle"
            :color="isEncountersSynced ? 'success' : 'error'"
            class="mr-3"
            small
          ></chip>
        </template>
        <!--
        <v-card-actions>
          <btn
            label="Sync All Data"
            :icon="icons.mdiSync"
            color="error"
            offline-disable
            @click="syncAllEncounters()"
          ></btn>
        </v-card-actions>
        -->
      </app-card-actions>
    </v-col>

    <v-col class="col-sm-6 pr-1p5 home-card">
      <!-- patient profiles -->
      <v-card class="home-stretch">
        <v-card-title>
          Patient Profiles
        </v-card-title>
        <div>
          <btn
            label="List Patient Profiles"
            :icon="icons.mdiAccountGroup"
            @click="route('list-patients')"
          ></btn>
        </div>
        <div>
          <btn
            label="Find Patient Profile"
            :icon="icons.mdiAccountSearch"
            @click="route('find-patient')"
          ></btn>
        </div>
        <div>
          <btn
            label="Create Patient Profile"
            :icon="icons.mdiAccountPlus"
            @click="route('add-patient')"
          ></btn>
        </div>
      </v-card>
    </v-col>

    <v-col class="col-sm-6 pl-1p5 home-card">
      <!-- encounter notes -->
      <v-card>
        <v-card-title>
          Encounter Notes
        </v-card-title>
        <btn
          label="List Encounters"
          :icon="icons.mdiAccountMultiple"
          color="success"
          @click="route('list-encounters')"
        ></btn>
      </v-card>

      <!-- quick entry -->
      <v-card>
        <v-card-title>
          Quick Entry
        </v-card-title>
        <btn
          label="Quick Entry"
          :icon="icons.mdiAccountClock"
          color="warning"
          @click="route('quick-entry')"
        ></btn>
      </v-card>
    </v-col>

    <Comments />
  </v-row>
</template>

<script>
import AppCardActions from '@core/components/app-card-actions/AppCardActions.vue'
import SyncEncounters from '@/mixins/SyncEncounters'
import Comments from '@/components/features/Comments.vue'
import { mapFields } from 'vuex-map-fields'
import { mapGetters } from 'vuex'
import {
  mdiSync, mdiCloudCheckVariant, mdiCloseCircle, mdiAccountGroup, mdiAccountMultiple,
  mdiAccountSearch, mdiAccountMultiplePlus, mdiAccountPlus, mdiAccountClock,
  mdiCommentText, mdiClockPlus,
} from '@mdi/js'

export default {
  components: { AppCardActions, Comments },
  mixins: [SyncEncounters], // Methods: syncAllEncounters()

  data() {
    const refreshEncounters = hideOverlay => {
      hideOverlay()
      if (this.online) {
        this.syncAllEncounters()
      } else {
        this.$store.dispatch('notify', { value: 'Offline, can\'t sync encounters and patients!', color: 'error' })
      }
    }

    return {
      debug: false,
      refreshEncounters,
      icons: {
        mdiSync,
        mdiCloudCheckVariant,
        mdiCloseCircle,
        mdiAccountGroup,
        mdiAccountMultiple,
        mdiAccountSearch,
        mdiAccountMultiplePlus,
        mdiAccountPlus,
        mdiAccountClock,
        mdiCommentText,
        mdiClockPlus,
      },
    }
  },
  computed: {
    ...mapFields(['online']),
    ...mapGetters('encounters', ['isSynced']),

    // This can be very inefficient since we actually just need the number of patients and encounters to sync.
    // ...mapState('encounters', { encounters: 'items' }),
    // ...mapState('patients', { patients: 'items' }),

    patientsToSyncCount() {
      // if (this.isSynced || !Array.isArray(this.patients)) return 0
      //
      // return this.patients.reduce((count, item) => count + (item.updated === true), 0)

      const patientsList = this.$store.state.patients.items
      if (Array.isArray(patientsList)) {
        return patientsList.reduce((count, item) => count + (item.updated ? 1 : 0), 0)
      }

      return null
    },
    encountersToSyncCount() {
      // if (this.isSynced || !Array.isArray(this.encounters)) return 0
      //
      // return this.encounters.reduce((count, item) => count + (
      //   !item.is_synced
      //   && item.created_by_user_id === this.$authUser.userId()
      // ), 0)
      const encountersArray = this.$store.state.encounters.items
      if (Array.isArray(encountersArray)) {
        return encountersArray.reduce((count, item) => count + (
          !item.is_synced && item.created_by_user_id === this.$authUser.userId()
            ? 1
            : 0
        ), 0)
      }

      return null
    },
    commentsToSyncCount() {
      // if (this.isCommentsSynced() || !Array.isArray(this.comments)) return 0
      const commentsList = this.$store.state.comments.items
      if (Array.isArray(commentsList)) {
        return commentsList.reduce((count, item) => count + (
          (!!item.updated || !!item.new)
        && item.created_by_user_id === this.$authUser.userId()
        ), 0)
      }

      return null
    },
    isEncountersSynced() {
      return this.$store.getters['encounters/isSynced']
    },
    isEncPullSuccess() {
      return this.$store.getters['encounters/isLastPullSuccess']
    },
    isPatsPullSuccess() {
      return this.$store.getters['patients/isLastPullSuccess']
    },
    isSyncing() {
      return this.$store.getters['encounters/syncing']
    },
  },
  methods: {
    route(name) {
      this.$router.push({ name })
    },
  },
}
</script>

<style lang="scss">
.sync-status .v-card__title {
  >span {
    font-size: 0.9rem;
    font-weight: 400;
    padding-left: 20px;
    >b {
      font-weight: 600;
    }
  }
}
.home-card {
  padding-bottom: 0;
  &.col:not(:first-of-type) {
    padding-top: 0;
  }
  .v-card {
    text-align: center;
    &.home-stretch {
      height: 100%;
    }
    button {
      width: 228px;
      margin-bottom: 25px;
    }
  }
  .v-card:not(:last-child) {
    margin-bottom: 12px;
  }
  > span {
    text-align: center;
    font-weight: 600;
    font-size: 1.1em;
    color: var(--v-accent-lighten4);
  }
}
</style>
